import { Schema } from "@griddo/core";

const schema: Schema.RichTextConfig = {
	paragraphStyles: [
		{ label: "Font size 12", className: "fontSize12" },
		{ label: "Font size 14", className: "fontSize14" },
		{ label: "Font size 16", className: "fontSize16" },
		{ label: "Font size 18", className: "fontSize18" },
		{ label: "Font size 20", className: "fontSize20" },
		{ label: "Font size 22", className: "fontSize22" },
		{ label: "Font size 24", className: "fontSize24" },
		{ label: "Font size 40", className: "fontSize40" },
		{ label: "Font size 48", className: "fontSize48" },
		{ label: "Font size 54", className: "fontSize54" },
		{ label: "Font size 80", className: "fontSize80" },
		{ label: "Font size 126", className: "fontSize126" },
		{ label: "Font size 170", className: "fontSize170" },
		{ label: "Line height 1.1", className: "lineHeight1" },
		{ label: "Line height 1.3", className: "lineHeight3" },
		{ label: "Line height 1.5", className: "lineHeight5" },
		{ label: "DM Serif Display", className: "serifDisplay" },
		{ label: "DM Sans", className: "sans" },
	],
};

export default schema;
