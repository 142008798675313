import { TableRowProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<TableRowProps> = {
	schemaType: "component",
	displayName: "Table Row",
	component: "TableRow",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Cell type",
					type: "ConditionalField",
					key: "cellType",
					mandatory: true,
					options: [
						{
							value: "text",
							title: "Text",
							name: "text",
						},
						{
							value: "link",
							title: "Link",
							name: "link",
						},
					],
					fields: [
						{
							title: "Row Label",
							type: "Wysiwyg",
							key: "title",
							full: true,
							condition: "text",
							humanReadable: true,
						},
						{
							title: "Row Label",
							type: "TextField",
							key: "textLink",
							condition: "link",
							humanReadable: true,
						},
						{
							title: "URL",
							type: "UrlField",
							key: "textURL",
							advanced: true,
							condition: "link",
						},
					],
				},
			],
		},
	],
	default: {
		component: "TableRow",
		title: "Text",
		cellType: "text",
		textLink: "-",
		textURL: undefined,
	},
};

export default schema;
