import { SearchResultsProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import BasicCard from "@schemas/components/BasicCard";
import { content, heading, subtitle } from "@schemas/presets";

const schema: Schema.Template<SearchResultsProps> = {
	schemaType: "template",
	displayName: "Search Results",
	component: "SearchResults",
	type: { label: "Static", value: "static" },
	singleInstance: true,

	content: [
		{
			...heading,
			isMockup: true,
		},
		{ ...subtitle },
		{ ...content },
		{
			type: "ComponentArray",
			title: "Related Content",
			whiteList: [
				"BasicContent",
				"BasicText",
				"CardCollection",
				"ContactInfo",
				"FeaturedFacts",
				"ImageBanner",
				"IntroForm",
				"Quote",
				"Table",
				"VideoPlaylist",
				"Widget",
				"Wysiwyg",
			],
			contentType: "modules",
			key: "relatedContent",
		},
	],

	default: {
		type: "template",
		templateType: "SearchResults",
		title: { content: "Encuéntralo en UTEF", tag: "h2" },
		content: "Puedes buscar por titulación, centro, evento...",
		relatedContent: {
			component: "Section",
			name: "Related Content",
			modules: [
				{
					component: "CardCollection",
					introType: "defaultIntro",
					additional: null,
					title: {
						content: "_Lo más buscado_",
						tag: "h2",
					},
					detail: null,
					content: null,
					currentLogo: "defaultLogo",
					defaultLogo: "logoMainHorizontalBn",
					elements: [
						{ ...BasicCard.default },
						{ ...BasicCard.default },
						{ ...BasicCard.default },
					],
					link: { component: "LinkModal" },
					layout: "layout2",
					kind: "BasicCard",
					subtheme: "default",
					anchorID: null,
					verticalSpacing: "medium",
					animation: "none",
				},
			],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/SearchResults/thumbnail@1x.png",
		"2x": "/thumbnails/templates/SearchResults/thumbnail@2x.png",
	},
};

export default schema;
