import { Schema } from "@griddo/core";

const schema: Schema.Translations = {
	en_GB: {
		global: {
			contact: "Contact",
			readMore: "Read more",
			viewMore: "View more",
			seeMore: "See more",
			viewAll: "View all",
			read: "Read",
			hour: "Hour",
			minute: "Minute",
		},
		fields: {
			searchPlaceholder: "Search for programs, school...",
			searchPlaceholderHeader: "Search for programs, events, centers...",
			searchHelpText: "Type here and press enter",
			explore: "Explore",
			name: "Name",
			lastName: "Last name",
			email: "Email",
			phone: "Phone",
			postalCode: "Postal code",
			programType: "What kind of studies are you interested in?",
			academicArea: "What academic areas are you interested in?",
			user: "User",
			password: "Password",
			contactForm: "Preferred contact method",
			town: "Town",
			select: "Select one option",
			school: "School",
			academicCourse: "Academic course",
			status: "Select your status",
			student: "Student",
			alumni: "Alumni",
			parents: "Parent",
			lecturer: "Counselor/teacher",
			other: "Other",
			yes: "Yes",
			no: "No",
			estimatedAdmissionDate: "Planned entry date",
			program: "Program",
			otherProgram: "Other studies",
			otherProgramHelp: "Tell us another studies you are interested in.",
			downloadDossier: "Check this box if you want to download our dossier.",
			callYou: "Check this box if you want us to contact you by phone.",
			forget: "Forgot your username/password?",
			academicData: "Academic Data",
			academicDataParent: "Academic Data (your child's school and course)",
			additional: "Additional information",
			meet: "How did you knew about us?",
			meetFamily: "Family",
			meetFormerStudent: "Former Student",
			meetGoogle: "Google",
			meetFair: "Unitour/other fair",
			meetSchool: "School visits",
			meetOpenDay: "Open day",
			meetOnlineSessions: "On-line Sessions",
			meetPress: "Press",
			meetAlumni: "Alumni association",
			meetCompany: "Company",
			meetSocial: "Social Networks",
			meetEmagister: "E-Magister",
			meetCareer: "Advance your career",
			meetOther: "Write here how",
			meetInternet: "Internet",
			meetOtherHelp:
				"Tell us how you found us if it is not any of the above options.",
			meet1: "Family or friends",
			meet2: "LinkedIn",
			meet3: "Facebook",
			meet4: "Instagram",
			meet6: "Press",
			meet7: "Other media",
			enrollment: "Enrollment",
			enrollmentPreferences: "Enrollment preferences",
			inPerson: "In person",
			online: "Online",
			attendees: "Number of people who will attend the event, including you.",
			attendeesHelp:
				"Remember that the capacity is limited. Out of consideration for other families, we ask you to reduce as much as possible the number of companions who will come with you on the day of the event.",
			comment: "Do you want to write any comments?",
			faculty: "Faculty/School",
			title: "Title",
			promotion: "Promotion (end year)",
			subscribe: "Subscription",
			subscribeLabel:
				"I want to stay informed and receive other communications from the university.",
			currentStatus: "Current studies / Employment status",
			currentJob: "Current studies or position",
			company: "Company/Institution",
			needOptions: "What do you need?",
			description: "Description",
			errors: {
				required: "Field is required",
				pattern: "Invalid value",
				phone: "Invalid phone number. Must contain only numbers, +()-. and x.",
				maxPrograms: "Select up to 3 programs",
				min: "Minimum value is 1",
			},
			discussionActivities:
				"I would like to be informed about the discussion activities",
			artActivities: "I would like to be informed about art activities",
			offCampusActivities:
				"I would like to be informed about off-campus activities",
			certificationsAndRegistrarions: "Certificates and registrations",
			equipmentRental: "Equipment rental",
			proposeAndActivity: "I would like to propose an activity",
			generalQuestions: "General questions",
			programInfo: "Which study do you want information about?",
		},
		formSteps: {
			personalInformation: "Personal Information",
			enrollmentOptions: "Enrollment Options",
			academicData: "Academic Data",
			furtherInformation: "Further Information",
			next: "Next step",
		},
		statusMessage: {
			thankYou: "Thank you",
			error: "Error",
			successMessage: "We will be in touch with you shortly",
			errorMessage: "An error has occurred",
			cta: "Submit another request",
			closed: "Registration for this event is closed",
			downloadCTA: "Download file",
			programDownloadCTA: "Download brochure for",
		},
		newsDetail: {
			shareNews: "Share this news:",
			latestNews: "Latest news",
		},
		newsletter: {
			button: "Sing up now!",
		},
		newsList: {
			dateAsc: "Date ascendant",
			dateDesc: "Date descendant",
			centers: "Centers",
			schools: "Schools",
			services: "Services",
			topics: "Topics",
			search: "Search",
		},
		programDetail: {
			viewProgram: "View program",
			application: "Application for admission",
			info: "More information",
			askInfo: "Ask for information",
		},
		programsList: {
			area: "Academic area",
			centers: "Centers",
			language: "Language",
			layout: "Grid layout",
		},
		eventsList: {
			search: "Search",
			eventType: "Event type",
			location: "Location",
			date: "Date",
			layout: "Calendar layout",
			event: "event",
			events: "events",
			eventOn: "Events on",
		},
		searchResults: {
			search: "You searched for",
			resultsFound: "results found",
			related: "Related",
			view: "You have seen",
			of: "of",
			results: "results",
			more: "Load more results",
			all: "All results",
			programs: "Programs",
			lecturers: "Lecturers",
			events: "Events",
			news: "News",
			pressReleases: "Press releases",
			QualityTemplate: "Quality and Prospective Service",
			ProgramDetail: "Program",
			EventDetail: "Event",
			NewsDetail: "News",
			PressReleasesDetail: "Press release",
			Lecturer: "Lecturer",
			noResults: "Sorry, no results were found. Please try a different search.",
		},
		heroVideo: {
			button: "Watch video",
		},
		quickFacts: {
			centers: "Centers",
			languages: "Languages",
			modality: "Modality",
			certifications: "Certification",
			format: "Format",
			stage: "Stage",
			intake: "Starts On",
			date: "Date",
			duration: "Duration",
			schedule: "Schedule",
			places: "Seats",
			locations: "Locations",
			schools: "Schools",
			academicAreas: "Academic areas",
		},
		VideoPlaylist: {
			orderBy: "Order by",
			dateAsc: "Date ascendant",
			dateDesc: "Date descendant",
			selectCat: "Select category",
		},
		Lists: {
			results: "results",
			noResults: "Try again by changing filters or keywords.",
			loading: "Just a few seconds, we are loading the results",
		},
		ListFilter: {
			filter: "Filter",
			apply: "Apply",
			clear: "Clear filters",
		},
		ImageGallery: {
			galleryOf: "Gallery of",
			photographs: "photographs",
			photograph: "photograph",
			of: "of",
		},
		StudyPlan: {
			credits: "Credits",
			others: "Others",
			studyPlan: "Study Plan",
		},
		ColorCollection: {
			copy: "Copy",
		},
		DownloadableItemCard: {
			downloadSvg: "Download SVG",
			downloadPng: "Download PNG",
			download: "Download",
		},
		TypographyPreviewer: {
			preview: "Preview the font family in different styles",
			fontFamily: "Font family",
			style: "Style",
			regular: "Regular",
			italic: "Italic",
			size: "Size",
			download: "Download the font here",
			dmserifPreviewer: "Type something to test DM Serif Display",
			dmsansPreviewer: "Type something to test DM Sans",
		},
		DownloadableIconCollection: {
			search: "Search",
			noResults: "Sorry, no results were found.",
		},
		a11y: {
			closeSearch: "Close search engine",
			openSearch: "Open search engine",
			searchLink: "Link to search page",
			closeMenu: "Close menu",
			openMenu: "Open menu",
			prev: "Previous",
			next: "Next",
			goTo: "Go to",
			homeLink: "Link to homepage",
			linkedin: "LinkedIn",
			readMore: "Read more about",
			download: "Download",
			visit: "Visit",
			closeModal: "Close modal",
			play: "Play video",
			goToVideo: "Go to video number",
			hidePassword: "Hide password",
			showPassword: "Show password",
		},
	},
	es_ES: {
		global: {
			contact: "Contactar",
			readMore: "Leer más",
			viewMore: "Ver más",
			seeMore: "Ver más",
			viewAll: "Ver todo",
			read: "Leer",
			hour: "Hora",
			minute: "Minuto",
		},
		fields: {
			searchPlaceholder: "Buscar programas, escuela...",
			searchPlaceholderHeader:
				"Prueba a buscar alguna titulación, un evento, un centro...",
			searchHelpText: "Escribe aquí y pulsa intro",
			explore: "Explorar",
			name: "Nombre",
			lastName: "Apellidos",
			email: "Email",
			phone: "Teléfono",
			postalCode: "Código Postal",
			programType: "¿Qué tipo de estudios te interesan?",
			academicArea: "¿Sobre qué áreas?",
			user: "Usuario",
			password: "Contraseña",
			contactForm: "Forma de contacto preferida",
			town: "Localidad",
			select: "Selecciona una opción",
			school: "Escuela",
			academicCourse: "Curso académico",
			status: "Indica tu situación",
			student: "Estudiante",
			alumni: "Antiguo Alumno",
			parents: "Padre/Madre",
			lecturer: "Orientador/profesor",
			other: "Otro",
			yes: "Sí",
			no: "No",
			estimatedAdmissionDate: "Año de preferencia para comenzar",
			program: "Programa",
			otherProgram: "Otros estudios",
			otherProgramHelp:
				"Indica los estudios que te interesan si no figuran en las opciones anteriores.",
			downloadDossier:
				"Marca esta casilla si deseas descargar nuestro dossier.",
			callYou: "Marca esta casilla si deseas que te llamemos por teléfono.",
			forget: "¿Olvidaste tu usuario y/o contraseña?",
			academicData: "Datos Académicos",
			academicDataParent: "Datos Académicos (colegio y curso de su hijo/a)",
			additional: "Información adicional",
			meet: "¿Cómo nos has conocido?",
			meetFamily: "Familia",
			meetFormerStudent: "Antiguo alumno",
			meetGoogle: "Google",
			meetFair: "Unitour/otra feria",
			meetSchool: "Centro Educativo (visitas colegio)",
			meetOpenDay: "Jornada Puertas Abiertas",
			meetOnlineSessions: "Sesiones On-line",
			meetPress: "Prensa",
			meetAlumni: "Asociación Alumnos",
			meetCompany: "Empresa",
			meetSocial: "Redes Sociales",
			meetEmagister: "E-Magister",
			meetCareer: "Avanza en tu carrera",
			meetInternet: "Internet",
			meetOther: "Escribe cómo aquí",
			meetOtherHelp:
				"Cuéntanos cómo nos has conocido si no está entre ninguna de las opciones anteriores.",
			meet1: "Familiares o amigos",
			meet2: "LinkedIn",
			meet3: "Facebook",
			meet4: "Instagram",
			meet6: "Prensa",
			meet7: "Otros medios",
			enrollment: "Inscripción",
			enrollmentPreferences: "Indica tus preferencias de inscripción",
			inPerson: "Presencial",
			online: "Online",
			attendees:
				"Indica el número de personas que asistirán al evento, incluido tú.",
			attendeesHelp:
				"Recuerde que el aforo es limitado. Por consideración a otras familias, le rogamos reduzca en lo posible el número de acompañantes que acudirán con usted el día del evento.",
			comment: "¿Deseas escribir alguna observación?",
			faculty: "Facultad/Escuela",
			title: "Titulación",
			promotion: "Promoción (año finalización)",
			subscribe: "Suscripción",
			subscribeLabel:
				"Deseo seguir informado y recibir otras comunicaciones de la universidad.",
			currentStatus: "Estudios actuales / Situación laboral",
			currentJob: "Estudios o puesto actual",
			company: "Empresa/Institución",
			needOptions: "¿Qué necesitas?",
			description: "Descripción",
			errors: {
				required: "Campo obligatorio",
				pattern: "El valor no es correcto",
				phone:
					"Número de teléfono incorrecto. Sólo debe contener números, +()-. y x",
				maxPrograms: "Selecciona hasta 3 programas",
				min: "El valor mínimo es 1",
			},
			discussionActivities: "Quiero informarme sobre actividades de Debate",
			artActivities: "Quiero informarme sobre actividades de las Artes",
			offCampusActivities:
				"Quiero informarme sobre actividades fuera del campus",
			certificationsAndRegistrarions: "Certificados y matriculaciones",
			equipmentRental: "Alquiler de material",
			proposeAndActivity: "Quiero proponer una actividad",
			generalQuestions: "Dudas generales",
			programInfo: "Sobre qué estudio quieres información",
		},
		formSteps: {
			personalInformation: "Información Personal",
			enrollmentOptions: "Opciones de Inscripción",
			academicData: "Datos académicos",
			furtherInformation: "Otra Información",
			next: "Siguiente paso",
		},
		statusMessage: {
			thankYou: "Muchas gracias",
			error: "Error",
			successMessage: "En breve nos pondremos en contacto contigo",
			errorMessage: "Se ha producido un error.",
			cta: "Realizar otra solicitud",
			closed: "La inscripción a este evento está cerrada",
			downloadCTA: "Puedes descargar el fichero",
			programDownloadCTA: "Puedes descargar el folleto de",
		},
		newsDetail: {
			shareNews: "Compartir noticia:",
			latestNews: "Últimas noticias",
		},
		newsletter: {
			button: "¡Regístrate ahora!",
		},
		newsList: {
			dateAsc: "Más antiguas primero",
			dateDesc: "Más recientes primero",
			centers: "Facultades y escuelas",
			schools: "Institutos y Cátedras",
			services: "Servicios",
			topics: "Temáticas",
			search: "Busca una noticia",
		},
		programDetail: {
			viewProgram: "Ver programa",
			application: "Solicitar admisión",
			info: "Más información",
			askInfo: "Solicitar información",
		},
		programsList: {
			area: "Área académica",
			centers: "Centros",
			language: "Idioma",
			layout: "Vista cuadrícula",
		},
		eventsList: {
			search: "Busca un evento",
			eventType: "Tipo de evento",
			location: "Ubicación",
			date: "Fecha",
			layout: "Vista de calendario",
			event: "evento",
			events: "eventos",
			eventOn: "Eventos para el",
		},
		searchResults: {
			search: "Has buscado",
			resultsFound: "resultados encontrados",
			related: "Relacionados",
			view: "Has visto",
			of: "de",
			results: "resultados",
			more: "Cargar más resultados",
			all: "Todos los resultados",
			programs: "Estudios",
			lecturers: "Claustro",
			events: "Eventos",
			news: "Noticias",
			pressReleases: "Notas de prensa",
			QualityTemplate: "Servicio de Calidad y Prospectiva",
			ProgramDetail: "Titulación",
			EventDetail: "Evento",
			NewsDetail: "Noticia",
			PressReleasesDetail: "Nota de prensa",
			Lecturer: "Claustro",
			noResults:
				"No hemos encontrado resultados que coincidan con tu búsqueda. Intenta con otras palabras clave o verifica la ortografía para obtener mejores resultados.",
		},
		heroVideo: {
			button: "Ver video",
		},
		quickFacts: {
			centers: "Facultad",
			languages: "Idiomas",
			modality: "Modalidad",
			certifications: "Certificación",
			format: "Formato",
			stage: "Intercambio",
			intake: "Fecha de inicio",
			date: "Fecha",
			duration: "Duración",
			schedule: "Horario",
			places: "Plazas",
			locations: "Lugar",
			schools: "Escuelas",
			academicAreas: "Áreas académicas",
		},
		VideoPlaylist: {
			orderBy: "Ordenar por",
			dateAsc: "Fecha ascendente",
			dateDesc: "Fecha descendente",
			selectCat: "Selecciona categoría",
		},
		Lists: {
			results: "resultados encontrados",
			noResults:
				"Intenta modificar los filtros o prueba con otra palabra clave.",
			loading: "Sólo unos segundos, estamos cargando los resultados",
		},
		ListFilter: {
			filter: "Filtrar",
			apply: "Aplicar filtros",
			clear: "Borrar todos los filtros",
		},
		ImageGallery: {
			galleryOf: "Galería de",
			photographs: "fotografías",
			photograph: "fotografía",
			of: "de",
		},
		StudyPlan: {
			credits: "Créditos",
			others: "Otros",
			studyPlan: "Plan de estudios",
		},
		ColorCollection: {
			copy: "Copiar",
		},
		DownloadableItemCard: {
			downloadSvg: "Descargar SVG",
			downloadPng: "Descargar PNG",
			download: "Descargar",
		},
		TypographyPreviewer: {
			preview: "Previsualiza la familia tipográfica en distintos estilos",
			fontFamily: "Familia tipográfica",
			style: "Estilo",
			regular: "Regular",
			italic: "Itálica",
			size: "Tamaño",
			download: "Descarga la tipografía aquí",
			dmserifPreviewer: "Escribe algo para probar la DM Serif Display",
			dmsansPreviewer: "Escribe algo para probar la DM Sans",
		},
		DownloadableIconCollection: {
			search: "Busca un icono",
			noResults: "No se han encontrado resultados.",
		},
		a11y: {
			closeSearch: "Cerrar buscador",
			openSearch: "Abrir buscador",
			searchLink: "Enlace al buscador",
			closeMenu: "Cerrar menú",
			openMenu: "Abrir menú",
			prev: "Anterior",
			next: "Siguiente",
			goTo: "Ir a",
			homeLink: "Enlace a la página principal",
			linkedin: "LinkedIn de",
			readMore: "Lee más sobre",
			download: "Descargar",
			visit: "Visitar",
			closeModal: "Cerrar ventana modal",
			play: "Reproducir vídeo",
			goToVideo: "Ir a vídeo",
			hidePassword: "Ocultar contraseña",
			showPassword: "Mostrar contraseña",
		},
	},
};

export default schema;
